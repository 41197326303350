.App {
  text-align: center;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --purple: #5906EB;
}

.logo{
  width: 120px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-purple{
    color: #fff !important;
    background-color: #5c60f5 !important;
    border-radius: .375rem !important;
    font-weight: 500 !important;
    text-align: center !important;
    user-select: none !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    border: 1px solid #5c60f5 !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}
.btn-purple:hover {
  color: #fff !important;
  background-color: #4e52d0 !important;
  border-color: #4a4dc4 !important;
}
.btn-purple:active {
  color: #fff !important;
  background-color: #4a4dc4 !important;
  border-color: #4548b8 !important;
}
.btn-purple:focus {
  color: #fff !important;
  background-color: #4e52d0 !important;
  border-color: #4a4dc4 !important;
  box-shadow: 0 0 0 .25rem rgba(116, 120, 247, .5) !important;
}

/*    headedr */

.nav-link.active {
  color: var(--purple) !important; /* Example active color */
}
.nav-link:hover{
  color: #97D2B5 !important;
}


header .nav-link{
  letter-spacing: -0.02em;
  color: #272657;
  font-weight: 600;
  font-size: 1.15rem;
}

header #lang-select{
  border: 0 !important;
  letter-spacing: -0.02em;
  color: #272657;
  font-weight: 600;
  font-size: 1.15rem;
}
header #lang-select:hover{
  color: #97D2B5 !important;
}
@media screen and (max-width: 550px) {
  header #lang-select{
    padding-left: 0 !important;
  }
}






/*    headedr */

/*    footer */
footer ul li a:hover{
  color: var(--purple) !important;
}
footer svg:hover{
  fill: var(--purple);
  transform: scale(1.05);
}
/* footer svg {
  transition: 0.3s ease;
} */




/*    footer */



.row3 .row1 h3{
  width: fit-content;
  font-size: 15px;
  text-transform: uppercase;
  padding: 12px 20px 12px 20px;
  background-color: #F8FAE7;
  border-radius: 99px 99px 99px 99px;
  color: #272657;
  font-weight: 600;
  box-shadow: 0px 2px 1px 0px rgba(75.00000000000001, 99.00000000000007, 147, 0.14901960784313725);
}

.row3 .row2 span{
  line-height: 1em;
  letter-spacing: -0.045em;
  font-size: 4rem;
  color: #272657;
  font-weight: 600;
}

.row3 .row2 p{
  color: #64607d;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

@media screen and (max-width: 550px) {
  .row3 .row2 span{
    font-size: 2rem;
  }

}

.owl-carousel .owl-stage {
  transition-timing-function: linear !important;
}

/* .owl-nav{
  position: relative;
  right: 0;
  top: -50px;
}
.owl-next{
  margin: 0 !important;
  position: absolute;
  background-color: #27265757 !important;
  color: #fff !important;
  width: 40px;
  height: 40px;
  top: -220px;
  right: 0 !important;
  transition: all .3s ease;
}
.owl-prev{
  margin: 0 !important;
  position: absolute;
  background-color: #27265757 !important;
  color: #fff !important;
  width: 40px;
  height: 40px;
  top: -220px;
  left: 0 !important;
  transition: all .3s ease;
}

.owl-next:hover{
  background-color: #272657 !important;
}
.owl-prev:hover{
  background-color: #272657 !important;
} */

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
	z-index: 1;
	width: 40px;
	height: 40px;
	background-color: #ccc;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translatey(-50%);
}

.owl-nav button span {
	/* font-size: 30px; */
	/* height: 100%; */
	display: block;
	/* width: 100%; */
}
.owl-carousel .owl-nav button.owl-prev {
	left: 0;
}
.owl-carousel .owl-nav button.owl-next {
	right: 0;
}
.owl-carousel .owl-nav {
	margin: 0;
}



/* .screenshot_slider .owl-item.center .item img {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
} */

.row3 .row3 .card-solutions{
  box-shadow: 0 0.15rem .75rem 0 rgba(33, 40, 50, 0.15);
  border-radius: 1.5rem !important;
  /* height: 450px; */
}

.row3 .row3 .card-solutions svg{
  width: 2em;
  height: auto;
  vertical-align: middle;
}
.row3 .row3 .card-solutions span{
  display: flex;
  justify-content: center;
  background-color: #79E2E629;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.row3 .row3 .card-solutions h3{
  line-height: 1em;
  letter-spacing: -0.045em;
  font-size: 2rem;
  color: #272657;
  font-weight: 600;
}
.row3 .row3 .card-solutions p{
  margin-bottom: 0;
  color: #64607d;
  font-size: 17px;
  line-height: 30px;

}












.row3 .owl-carousel .owl-stage-outer {
  /* overflow: unset !important; */
}

.custom-single-value {
  display: flex;
  align-items: center;
}

.custom-option {
  display: flex;
  align-items: center;
}
